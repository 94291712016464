var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"campaign-filter__form"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"campaign-date-filter"}},[_vm._v(" DATE RANGE ")])]),_c('date-range-picker',{attrs:{"dates":_vm.filters.dates},on:{"input":_vm.onSelectDateRange}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6 mt-3"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"campaign-subject-filter"}},[_vm._v(" SUBJECT LINE ")])]),_c('v-text-field',{attrs:{"id":"campaign-subject-filter","label":"Subject Line","outlined":""},model:{value:(_vm.filters.subject),callback:function ($$v) {_vm.$set(_vm.filters, "subject", $$v)},expression:"filters.subject"}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6 mt-3"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"campaign-sending-domain-filter"}},[_vm._v(" FROM DOMAIN ")])]),_c('v-autocomplete',{attrs:{"id":"campaign-sending-domain-filter","items":_vm.sendingDomains,"label":"Domain","item-value":"from_domain","item-text":"from_domain","multiple":"","small-chips":"","deletable-chips":"","menu-props":_vm.menuProps,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.deleteChip(item.from_domain, 'from_domains')}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.from_domain)+" ")])]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.from_domain)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.filters.from_domains),callback:function ($$v) {_vm.$set(_vm.filters, "from_domains", $$v)},expression:"filters.from_domains"}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6 mt-3"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"campaign-ip-address-filter"}},[_vm._v(" IP ADDRESS ")])]),_c('v-autocomplete',{attrs:{"id":"campaign-ip-address-filter","items":_vm.sendingIPs,"label":"IP Address","multiple":"","item-value":"sending_ip","item-text":"sending_ip","small-chips":"","deletable-chips":"","allow-overflow":false,"menu-props":_vm.menuProps,"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.deleteChip(item.sending_ip, 'sending_ips')}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.sending_ip)+" ")])]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.sending_ip)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.filters.sending_ips),callback:function ($$v) {_vm.$set(_vm.filters, "sending_ips", $$v)},expression:"filters.sending_ips"}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6 mt-3"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"campaign-mailbox-provider-filter"}},[_vm._v(" MAILBOX PROVIDER ")])]),_c('v-autocomplete',{attrs:{"id":"campaign-mailbox-provider-filter","label":"Mailbox Provider","items":_vm.mailboxProviders,"item-text":"name","item-value":"seed_domains","menu-props":_vm.menuProps,"multiple":"","small-chips":"","deletable-chips":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.deleteChip(item.seed_domains, 'seed_domains')}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.filters.seed_domains),callback:function ($$v) {_vm.$set(_vm.filters, "seed_domains", $$v)},expression:"filters.seed_domains"}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('filter-form-buttons',{attrs:{"reset-form":_vm.resetForm,"is-form-valid":_vm.isFormValid}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }