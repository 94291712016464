<template>
  <div id="campaign-filter__form">
    <v-form
      ref="form"
      v-model="isFormValid"
      lazy-validation
      @submit.prevent="handleSubmit"
    >
      <div class="form-group">
        <div class="text-left mb-6">
          <label for="campaign-date-filter" class="overline font-weight-large">
            DATE RANGE
          </label>
        </div>
        <date-range-picker :dates="filters.dates" @input="onSelectDateRange" />
      </div>

      <v-divider class="border-light-grey" />
      <div class="form-group">
        <div class="text-left mb-6 mt-3">
          <label
            for="campaign-subject-filter"
            class="overline font-weight-large"
          >
            SUBJECT LINE
          </label>
        </div>

        <v-text-field
          id="campaign-subject-filter"
          label="Subject Line"
          v-model="filters.subject"
          outlined
        />
      </div>
      <v-divider class="border-light-grey" />
      <div class="form-group">
        <div class="text-left mb-6 mt-3">
          <label
            for="campaign-sending-domain-filter"
            class="overline font-weight-large"
          >
            FROM DOMAIN
          </label>
        </div>

        <v-autocomplete
          id="campaign-sending-domain-filter"
          :items="sendingDomains"
          v-model="filters.from_domains"
          label="Domain"
          item-value="from_domain"
          item-text="from_domain"
          multiple
          small-chips
          deletable-chips
          :menu-props="menuProps"
          outlined
        >
          <template v-slot:selection="{ item, attrs, selected, select }">
            <v-chip
              class="filter-light-green-chip body-2 text--primary"
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="deleteChip(item.from_domain, 'from_domains')"
            >
              {{ item.from_domain }}
            </v-chip>
          </template>
          <template v-slot:item="{ on, attrs, item }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action class="mr-4">
                <v-checkbox :input-value="active" />
              </v-list-item-action>
              <v-list-item-content class="body-2 text--primary">
                {{ item.from_domain }}
                <v-spacer></v-spacer>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <v-divider class="border-light-grey" />
      <div class="form-group">
        <div class="text-left mb-6 mt-3">
          <label
            for="campaign-ip-address-filter"
            class="overline font-weight-large"
          >
            IP ADDRESS
          </label>
        </div>

        <v-autocomplete
          id="campaign-ip-address-filter"
          :items="sendingIPs"
          label="IP Address"
          v-model="filters.sending_ips"
          multiple
          item-value="sending_ip"
          item-text="sending_ip"
          small-chips
          deletable-chips
          :allow-overflow="false"
          :menu-props="menuProps"
          outlined
        >
          <template v-slot:selection="{ item, attrs, selected, select }">
            <v-chip
              class="filter-light-green-chip body-2 text--primary"
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="deleteChip(item.sending_ip, 'sending_ips')"
            >
              {{ item.sending_ip }}
            </v-chip>
          </template>
          <template v-slot:item="{ on, attrs, item }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action class="mr-4">
                <v-checkbox :input-value="active" />
              </v-list-item-action>
              <v-list-item-content class="body-2 text--primary">
                {{ item.sending_ip }}
                <v-spacer></v-spacer>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <v-divider class="border-light-grey" />
      <div class="form-group">
        <div class="text-left mb-6 mt-3">
          <label
            for="campaign-mailbox-provider-filter"
            class="overline font-weight-large"
          >
            MAILBOX PROVIDER
          </label>
        </div>

        <v-autocomplete
          id="campaign-mailbox-provider-filter"
          label="Mailbox Provider"
          v-model="filters.seed_domains"
          :items="mailboxProviders"
          item-text="name"
          item-value="seed_domains"
          :menu-props="menuProps"
          multiple
          small-chips
          deletable-chips
          outlined
        >
          <template v-slot:selection="{ item, attrs, selected, select }">
            <v-chip
              class="filter-light-green-chip body-2 text--primary"
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="deleteChip(item.seed_domains, 'seed_domains')"
            >
              {{ item.name }}
            </v-chip>
          </template>
          <template v-slot:item="{ on, attrs, item }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action class="mr-4">
                <v-checkbox :input-value="active" />
              </v-list-item-action>
              <v-list-item-content class="body-2 text--primary">
                {{ item.name }}
                <v-spacer></v-spacer>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <v-divider class="border-light-grey" />
      <filter-form-buttons
        :reset-form="resetForm"
        :is-form-valid="isFormValid"
      />
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FilterFormButtons, DateRangePicker } from "../shared";
/**
 * Campaigns page filers drawer
 */
export default {
  name: "CampaignFilterForm",
  /**
   * Send all filter data to parent component
   */
  emits: ["filter"],
  /**
   * -------------- Components --------------
   */
  components: {
    FilterFormButtons,
    DateRangePicker,
  },
  /**
   * ---------------Data attributes----------------
   */
  data() {
    return {
      isFormValid: false,
      filters: {
        dates: [],
        from_domains: [],
        sending_ips: [],
        seed_domains: [],
        account_ids: [],
        subject: "",
      },
      dateMenu: false,
      menuProps: {
        bottom: true,
        offsetY: true,
        maxHeight: 250,
      },
    };
  },

  /**
   * -------------------Computed Properties----------------
   */
  computed: {
    ...mapGetters({
      accounts: "account/accounts",
      sendingDomains: "account/sendingDomains",
      sendingIPs: "account/sendingIPs",
      mailboxProviders: "account/mailboxProviders",
      settings: "settings/campaign",
      selectedAccount: "ui/selectedAccount",
      selectedAccountId: "ui/selectedAccountId",
    }),

    /**
     * Get account Id
     */
    account_id() {
      return this.$route.params.id ?? 0;
    },
  },
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    fetchFilterRecords: {
      type: Boolean,
      default: false,
      required: true,
    },
    cancelToken: {
      type: [String, Object],
    },
  },
  /**
  |--------------------------------------------------
  | Watch Properties
  |--------------------------------------------------
  */
  watch: {
    fetchFilterRecords() {
      const payload = { cancelToken: this.cancelToken?.token };

      this.getSendingDomains({
        ...payload,
        account_ids: this.selectedAccountId,
      });
      this.getSendingIPs({ ...payload, account_id: this.selectedAccountId });
    },
  },

  /**
   * ---------------Methods ------------------------
   */
  methods: {
    ...mapActions({
      getSendingDomains: "account/getSendingDomains",
      getSendingIPs: "account/getSendingIPs",
      getMailboxProviders: "account/getMailboxProviders",
      setCampaigntTableSettings: "settings/setCampaigntTableSettings",
    }),

    /**
     * Reset all form data
     */
    resetForm() {
      this.filters.dates = [];
      this.$refs.form.reset();
      this.$emit("filter", {});
    },

    /**
     * On Submit emitted formdata to parent component
     */
    handleSubmit() {
      const filters = {};

      Object.keys(this.filters).forEach((key) => {
        if (key == "dates") {
          const [from_date, to_date] = this.filters.dates.sort();
          filters.from_date = from_date;
          filters.to_date = to_date;
        } else if (Array.isArray(this.filters[key])) {
          filters[key] = this.filters[key].length
            ? this.filters[key].join(",")
            : undefined;
        } else if (this.filters[key]) {
          filters[key] = this.filters[key];
        }
      });

      this.$emit("filter", filters);
    },

    /**
     * Update date range on filters data attributes
     */
    onSelectDateRange(vals) {
      this.filters.dates = vals;
    },

    /**
     * Remove item from chip
     * @param item {Object}
     * @param key {String}
     */
    deleteChip(itemText, key) {
      const index = this.filters[key]?.findIndex(
        (provider) => provider === itemText
      );
      if (index >= 0) this.filters[key]?.splice(index, 1);
    },
  },

  async created() {
    this.getSendingDomains({ account_ids: this.account_id });
    this.getSendingIPs({ account_id: this.account_id });
    this.getMailboxProviders({ account_id: this.account_id });

    // Update filters
    if (this.settings) {
      let { filters } = this.settings.table;

      Object.keys(this.filters).forEach((key) => {
        if (filters[key]) {
          this.filters[key] = Array.isArray(this.filters[key])
            ? filters[key]?.split(",")
            : filters[key];
        }
      });

      if (filters.from_date && filters.to_date) {
        this.filters.dates = [filters.from_date, filters.to_date];
      }
    }
  },
};
</script>
