<template>
  <section id="account-campaigns">
    <page-header :breadcrumbs="breadcrumbs">
      <template #title>
        <v-col class="my-4" md="12" lg="6">
          <h5 class="text-h5 secondary--font font-weight-large text--primary">
            {{ $dictionary.app.accountCampaigns.index.title }}
          </h5>
        </v-col>
        <v-col
          class="button d-flex align-center justify-end mb-lg-0 mb-2"
          md="12"
          lg="6"
        >
          <filter-button
            class="campaigns-filter-btn"
            :isFilterApplied="isFilterApplied"
            @toggle-right-bar="toggleRightBar(!isRightDrawerOpen)"
          />

          <csv-button
            :data="filteredAccountCampaigns"
            fileName="campaigns"
            :loading="isLoading"
            :fields="csvFields"
            :labels="labels"
          />
        </v-col>
      </template>
    </page-header>

    <!----------------------- Campaigns table starts ----------------------->
    <campaigns-table
      ref="table"
      :isLoading="isLoading"
      :campaignAccount="campaignAccount"
      :accountCampaigns="filteredAccountCampaigns"
      @get-campaigns="getAccountCampaignsList"
      @navigate-to-mailbox="mailboxProviderNavigator"
      :tableSettings="settings.table"
      :updateSettings="updateSettings"
    />
    <!----------------------- Campaigns table ends ----------------------->

    <!----------------------- Snackbar starts ----------------------->
    <snackbar
      :snackBarType="$appConfig.snackbar.snackbarTypes.error"
      :snackbarValue="hasError"
      @close="hasError = !hasError"
    >
      {{ errorMessage }}
    </snackbar>
    <!-- Snackbar ends -->

    <!-- Right Side Bar -->

    <right-side-bar
      class="campaign-filter-drawer"
      :isRightDrawerOpen="isRightDrawerOpen"
      :campaignAccount="campaignAccount"
      title="Filter"
      :prepend="true"
      :isAbsolute="false"
      :app="true"
      :clipped="true"
      @toggle-right-bar="toggleRightBar"
    >
      <campaign-filter-form
        ref="filterForm"
        @filter="filterData"
        :cancel-token="cancelToken"
        :fetch-filter-records="fetchFilterRecords"
      />
    </right-side-bar>
    <!-- Right side bar end -->
  </section>
</template>

<script>
import { CsvButton } from "@/components/widgets";
import { CampaignsTable } from "@/components/tables";
import { mapGetters, mapActions } from "vuex";
import { PageHeader } from "@/components/shared";
import { RightSideBar } from "../components/drawers";
import FilterButton from "@/components/FilterButton";
import CampaignFilterForm from "@/components/forms/CampaignFilterForm";
import {
  isEmpty,
  isType,
  getSelectedFilterDayDate,
} from "@/utils/common.utils";
import { getAccountCampaigns } from "@/services";
import { getCancelToken, isCancelRequest } from "@/utils/api.utils";

/**
 * Campaigns page
 */
export default {
  name: "Campaigns",
  /**
   * ---------------- Components ------------------
   */
  components: {
    CsvButton,
    CampaignsTable,
    PageHeader,
    CampaignFilterForm,
    RightSideBar,
    FilterButton,
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      hasError: false,
      isRightDrawerOpen: false,
      labels: {
        subject: "Subject Line",
        id: "Campaign ID",
        inbox_count: "Inbox",
        inbox: "Inbox %",
        spam_count: "Spam",
        spam: "Spam %",
        missing_count: "Missing",
        missing: "Missing %",
        first_seen: "First Seen",
        from_domain: "From Domain",
        duration: "Duration",
      },
      cancelToken: null,
      campaigns: [],
      fetchFilterRecords: false,
    };
  },

  /***
   * ---------------Watch --------------------
   */

  watch: {
    async "selectedAccount.account_id"(val) {
      let { sort_by, sort_order } = this.settings.table;
      sort_by = `${sort_by ?? ""} ${sort_order}`;

      await this.getAccountCampaignsList({ account_id: val, "q[s]": sort_by });
      this.toogleFetchFilterRecords();
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    ...mapActions({
      getAccountCampaigns: "account/getAccountCampaigns",
      setCampaigntTableSettings: "settings/setCampaigntTableSettings",
      setCampaignAccount: "ui/setCampaignAccount",
    }),
    /**
     * toogleFetchFilterRecords
     * @description Toogles fetchFilterRecords data property
     */
    toogleFetchFilterRecords() {
      this.fetchFilterRecords = !this.fetchFilterRecords;
    },
    /**
     * campaignRequestPayload
     * @description Campaigns request payload
     */
    campaignRequestPayload(params) {
      const { filters, itemsPerPage } = this.settings.table;
      const { from_date, to_date } = filters;
      return {
        ...filters,
        per_page: itemsPerPage,
        account_id: this.campaignAccount.account_id,
        ...params,
        from_date,
        to_date,
      };
    },
    /**
     * getAccountCampaignsList
     * @description Fetches campaigns of an account
     */
    async getAccountCampaignsList(params) {
      try {
        if (this.cancelToken) this.cancelToken.cancel("Request cancelled");

        const dataParams = this.campaignRequestPayload(params);

        this.isLoading = true;
        this.cancelToken = getCancelToken().source();

        const response = await getAccountCampaigns({
          ...dataParams,
          cancelToken: this.cancelToken?.token,
        });

        this.campaigns = response?.data ?? [];
        this.isLoading = false;
      } catch (error) {
        if (!isCancelRequest(error)) {
          this.isLoading = false;
        }
      }
    },
    /**
     * Navigates to the mailbox provider page
     * @param {String} campaign_id - Camapign id whose mailbox providers are to be viewed
     * @param {String} campaignSubject - Campaign subject which is to be diaplayed in the view
     */
    mailboxProviderNavigator({ campaign_id }) {
      const navigateTo = `${this.$route.path}/${campaign_id}${this.$paths.mailboxProviders}`;
      this.$router.push({ path: navigateTo });
    },

    /**
     * Toggle Filter side bar
     */
    toggleRightBar(val) {
      this.isRightDrawerOpen = val;
    },

    /**
     * Filter data
     * @param {Object} data Filters key values
     */
    filterData(data) {
      const filters = isEmpty(data) ? {} : data;
      this.isRightDrawerOpen = false;

      this.updateSettings({ filters });

      this.$refs.table.resetPageCount();
      this.getAccountCampaignsList({ ...filters, page: 1 });
    },
    /**
     * Dispatches action in the store and updates the campaing view settings
     */
    updateSettings(settings) {
      this.setCampaigntTableSettings(settings);
    },

    /**
     * Check props value
     */
    hasAnyPropsValue(object) {
      return object
        ? Object.values(object).some((prop) =>
            isType(prop, "object") ? !isEmpty(prop) : prop
          )
        : false;
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      accountCampaigns: "account/accountCampaigns",
      filterAccountCampaigns: "account/filteredCampaigns",
      settings: "settings/campaign",
      selectedAccount: "ui/selectedAccount",
      dashboard: "settings/dashboard",
    }),
    /**
     * filteredAccountCampaigns
     * @description Filters the campaigns
     */
    filteredAccountCampaigns() {
      return this.filterAccountCampaigns(this.campaigns ?? []);
    },
    /**
     * Account id whose campaigns are to be viewed
     * @type {String}
     */
    campaignAccount() {
      const { id } = this.$route.params;
      const { from_date, to_date } = getSelectedFilterDayDate(
        this.dashboard.selectedDay
      );
      return { account_id: id, from_date, to_date };
    },

    /**
     * Breadcrumbs routes and names which will be shown at the top of the page
     * @type {Array}
     */
    breadcrumbs() {
      return [
        {
          title: this.$dictionary.app.accountCampaigns.index.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
      ];
    },
    csvFields() {
      return Object.keys(this.labels);
    },

    /**
     * Check filter applied or not
     * @returns {Boolean}
     */
    isFilterApplied() {
      return (
        !isEmpty(this.settings.table.filters) &&
        this.hasAnyPropsValue(this.settings.table.filters)
      );
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.filterAccountCampaigns(this.campaigns);
  },
};
</script>
